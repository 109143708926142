import { Placeholder } from '@components/Placeholder'
import { GetStaticProps } from 'next'
import Head from 'next/head'
import * as React from 'react'

type Env = {
  key: string
  value: string | number | boolean | undefined
}

export const getStaticProps: GetStaticProps = async (context) => {
  const envKeys = [
    'NEXT_PUBLIC_VERCEL_ENV',
    'NEXT_PUBLIC_VERCEL_URL',
    'NEXT_PUBLIC_VERCEL_GIT_PROVIDER',
    'NEXT_PUBLIC_VERCEL_GIT_REPO_SLUG',
    'NEXT_PUBLIC_VERCEL_GIT_REPO_OWNER',
    'NEXT_PUBLIC_VERCEL_GIT_REPO_ID',
    'NEXT_PUBLIC_VERCEL_GIT_COMMIT_REF',
    'NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA',
    'NEXT_PUBLIC_VERCEL_GIT_COMMIT_MESSAGE',
    'NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_LOGIN',
    'NEXT_PUBLIC_VERCEL_GIT_COMMIT_AUTHOR_NAME',
  ]
  const envs: Env[] = envKeys.reduce<{ key: string; value: string }[]>(
    (acc, cur) => {
      const value = process.env?.[cur]
      if (value) acc.push({ key: cur, value })
      return acc
    },
    []
  )

  return { props: { envs } }
}

type HomeProps = {
  envs: Env[]
}

function Home(props: HomeProps) {
  return (
    <>
      <Head>
        <link rel="shortcut icon" type="image/png" href="/icon.png" />
        {props.envs.map((i) => (
          <meta key={i.key} property={i.key} content={`${i.value}`} />
        ))}
      </Head>
      <Placeholder emoji="🎣" text="Purposity Webhooks" />
    </>
  )
}

export default Home
